$gutter: 1rem; //for primeflex grid system

/* Layout */
@import 'assets/layout/styles/layout/layout.css';

/* PrimeNG */
@import '/node_modules/primeng/resources/primeng.min.css';
@import '/node_modules/primeflex/primeflex.css';
@import '/node_modules/primeicons/primeicons.css';

/* Other */
@import '/node_modules/ngx-toastr/toastr.css';

/* Rule Engine */
@import '../../prox-ui/src/assets/css/codeeffects/codeeffects.common.css';
@import '../../prox-ui/src/assets/css/codeeffects/codeeffects.white.css';

// ag-grid styles
// fix for ag-grid double scrollbars ... ugh
.ag-body-viewport::-webkit-scrollbar,
.ag-center-cols-viewport::-webkit-scrollbar {
  display: none !important;
}

.ag-val-error-tooltip-title {
  font-size: 12pt;
  margin: auto;
  text-align: center;
  color: white;
  background-color: red;
  padding: 5px;
}

.ag-val-error-tooltip-body {
  padding: 5px;
  font-size: 10pt;
  background-color: black;
  color: white;
}

.ag-info-tooltip-title {
  font-size: 12pt;
  margin: auto;
  text-align: center;
  // color: white;
  // background-color: blue;
  padding: 5px;
}

.ag-info-tooltip-body {
  padding: 5px;
  font-size: 10pt;
  background-color: black;
  color: white;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-rich-select-list {
  background-color: whitesmoke;
}

// inside grid cells
.ag-cell {
  .p-button {
    padding: 5px !important;
    font-size: 12px !important;
  }
}

.ag-details-row {
  padding: 10px;
}

.loading {
  cursor: wait; /* Or 'progress' */
}

.row-in-violation {
  background-color: yellow !important ;
}

/* .need_order {
  background-color: lightcoral !important;
} */

// styles for forms - should eliminate most component.scss files
form {
  .p-field {
    margin-bottom: 0 !important;
  }

  .ui-message.ui-widget.ui-corner-all.ui-message-error.ui-messages-error.ng-star-inserted {
    color: red;
    font-size: 12px;
    padding-top: 1px;
    margin-bottom: 5px;
  }

  .ui-message.ui-widget.ui-corner-all.ui-message-error.ui-messages-error.ng-star-inserted:before {
    content: '\00B0';
    margin-right: 6px;
    padding-top: 1px;
    margin-bottom: 5px;
  }

  .group-lead {
    font-size: larger;
    font-weight: 400;
    margin-bottom: 20px;
  }

  /*   .card {
    width: 900px;
  } */
}

.job-order-product-card {
  gap: 1.5rem;
  padding-top: 0.5rem;
  margin-top: 1rem;
  cursor: pointer;
  &:hover {
    background-color: azure;
  }
}

.p-picklist {
  .p-picklist-filter-container {
    padding: 0;
  }
  .p-picklist-header {
    padding: 0.5rem;
  }

  .p-picklist-list {
    min-height: 5rem;
  }
}

.small-dropdown .p-dropdown-label {
  font-size: 12px !important;
  padding: 3px;
}

.cell-editable {
  background-color: rgba(255, 255, 160, 0.5);
}

.cell-error {
  border: solid 2px;
  border-color: red;
  border-right: 2px solid red !important;
}

.cell-numeric {
  justify-content: flex-end;
}

.cell-right-justify {
  justify-content: flex-end;
}

// prime-ng dialog
p-confirmdialog,
p-dynamicdialog {
  .p-dialog.red-header {
    .p-dialog-header {
      background-color: #d30000;
      color: white;
      padding-bottom: 11px;
    }
    .p-dialog-content {
      padding-top: 10px;
    }
  }

  .p-dialog.yellow-header {
    .p-dialog-header {
      background-color: #ffff00;
      padding-bottom: 11px;
    }
    .p-dialog-content {
      padding-top: 10px;
    }
  }

  .p-dialog.goldenrod {
    .p-dialog-header {
      background-color: #ffffde;
      padding-bottom: 11px;
    }
    .p-dialog-content {
      // padding-top: 10px;
      background-color: #ffffde;
    }
  }

  a {
    color: #0000bb;
    text-decoration: underline;
    cursor: pointer;
  }
}

// prime-ng galleria
div.p-galleria div.p-galleria-caption {
  padding-bottom: 0.5rem;
}

// prime-ng menu
.p-menu {
  .p-menuitem-link.p-menuitem-link-active {
    background-color: #d8e4f0;
    border: 2px solid gray;
  }
}

// prime-ng splitter
.p-splitter {
  border: none;
}

// HTML Editor menu bar
// class="border-none hover:text-white hover:surface-500"
.html-editor-menu-bar {
  button {
    border: none;
    padding: 0.5rem 0.75rem;
    &:hover,
    &.is-active {
      color: white;
      background-color: rgb(158, 158, 158);
    }
  }
}

// TipTap editor content
.tiptap, .notification {
  span[data-type='mention'] {
    color: purple;

    // &::after {
    //   content: '}}';
    // }
  }

  /* Table-specific styling - this matches the server-added styles when rendering notifications */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;
  }

  table td,
  table th {
    border: 1px solid #BBBBBB;
    min-width: 1em;
    padding: 6px 8px;
    position: relative;
    vertical-align: top;
    margin-bottom: 0;
  }

  table th {
    background-color: #DDDDDD;
    font-weight: bold;
    text-align: left;
  }

  td p,
  th p {
    margin: 0;
  }

  /* Additional table styling for table editing in tiptap - not used in rendering */
  table {
    .selectedCell:after {
      background: lightgray;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: purple;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }  
}

.layout-wrapper {
  .layout-submenu {
    background-color: color-mix(in srgb, var(--d-sidebar-bg-color), #fff 60%);
    padding-top: 1px;
  }
  .p-menu .p-menuitem-link .p-menuitem-text {
    color: color-mix(in srgb, var(--d-menuitem-text-color), #000, 15%);
  }
  .p-menu .p-menuitem-link .p-menuitem-icon {
    color: color-mix(in srgb, var(--d-menuitem-text-color), #000, 15%);
  }
  .p-menu .p-submenu-header {
    background-color: var(--d-sidebar-bg-color);
    color: var(--d-menuitem-text-color);
  }
}

.layout-wrapper {
  .layout-form-menu {
    background-color: color-mix(in srgb, var(--d-sidebar-bg-color), #fff 90%);
    .p-menu {
      .p-menuitem-link .p-menuitem-text {
        color: color-mix(in srgb, var(--d-menuitem-text-color), #000, 40%);
      }
      .p-menuitem-link .p-menuitem-icon {
        color: color-mix(in srgb, var(--d-menuitem-text-color), #000, 40%);
      }
      .p-menu-separator {
        border-top: 1px solid #304562;
        margin: 0.25rem 0;
      }
      .p-submenu-header {
        background-color: var(--d-sidebar-bg-color);
        color: var(--d-menuitem-text-color);
      }
    }  
  }
}

.loading-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

// bug fix for primeng separator - - https://github.com/primefaces/primevue/issues/690
.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid !important;
}
.p-divider-dashed.p-divider-horizontal:before {
  border-top-style: dashed !important;
}
.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid !important;
  border-left-color: lightgray;
}

.row-excluded {
  background-color: darkorange;
}
//

// Proximity css
.prox-group-label {
  font-size: 1.5rem;
  margin-bottom: 5px;
  font-variant: small-caps;
}

.prox-label-name {

}

.prox-label-data {
  font-size:larger;
}

.prox-label-data-emphasis {
  font-size:larger;
  color: red
}

.prox-error {
  color: red;
}

.prox-fade {
  opacity: 0.6;
}

.prox-mini-icon {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  i {
    color: white;
    padding-top: 0.25rem;
    font-size: 0.8rem;
  }
}

.prox-ag-grid-list {
  height: 100%;
  width: 100%;
  display: block;
}

.prox-error-row {
  background-color: orange;
}

app-layout,
prox-ag-grid {
  & .grid-footer {
    position: relative;
    bottom: 25px;
    left: 15px;
    height: 0;
    width: 350px;
    font-size: 12px;

    a {
      color: rgba(0, 0, 0, 0.6);
      cursor: pointer;
    }
  }

  .layout-footer {
    position: relative;
    z-index: -1;
  }
}

// prime-ng-tabview
p-tabview.prox-tabview {
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .p-tabview-panels {
    flex-grow: 1;
  }

  .p-tabview-panel {
    height: 100%;
  }
}

.p-menu.prox-menu {
  width: fit-content;

  li.p-menuitem {
    .p-menuitem-text * {
      margin-bottom: 0;
    }
    .p-menuitem-link {
      // squeeze items together so long menu will fit
      padding-top: .25rem;
      padding-bottom: .25rem;
    }

    .p-menuitem-link:focus {
      background-color: #d0d7d7;
      // background-color: #e9ecef;
    }
  }
}

.p-menu.prox-product-menu {
  p-menu {
    color: green;
    background-color: green;
  }
  li.p-menuitem {
    .p-menuitem-text * {
      margin-bottom: 30;
    }
    .p-menuitem-link {
      // squeeze items together so long menu will fit
      padding-top: .25rem;
      padding-bottom: .25rem;
      color: red;
    }

    .p-menuitem-link:focus {
      background-color: #d0d7d7;
      // background-color: #e9ecef;
    }
  }
}

.prox-card {
  background-color: blue !important;
}